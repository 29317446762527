<script lang="ts" setup>
  import { computed, ref } from 'vue'
  import { DatePicker } from 'v-calendar'
  import { useScreens } from 'vue-screen-utils'
  import moment from 'moment'

  const props = defineProps({
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    class: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    range: {
      type: Boolean,
      default: false,
    },
    startFilter: {
      type: Function,
      default: (param: any) => moment(param).startOf('day').format(),
    },
    endFilter: {
      type: Function,
      default: (param: any) => moment(param).endOf('day').format(),
    },
    filter: {
      type: Function,
      default: (param: any) => moment(param).format(),
    },
    columns: {
      type: [String, Number],
      default: undefined,
    },
  })
  const { mapCurrent } = useScreens({
    xs: '575px',
    sm: '768px',
    md: '992px',
    lg: '1200px',
  })
  const ecolumns = mapCurrent({ lg: 2 }, 1)

  const selectAttribute = {
    highlight: {
      base: {
        style: {
          width: '40px',
          height: '40px',
          backgroundColor: ' #f8f8f8',
        },
      },
      startEnd: {
        style: {
          width: '40px',
          height: '40px',
          color: '#fff',
          backgroundColor: '#1B4F32',
        },
      },
    },
    content: {
      base: {
        style: {
          width: '40px',
          height: '40px',
          fontWeight: '400',
        },
      },
      startEnd: {
        style: {
          width: '40px',
          height: '40px',
          color: '#fff',
          fontWeight: '500',
        },
      },
    },
  }
  const attr = [
    {
      highlight: {
        base: {
          style: {
            width: '40px',
            height: '40px',
            backgroundColor: ' #f8f8f8',
          },
        },
        startEnd: {
          style: {
            width: '40px',
            height: '40px',
            color: '#fff',
            backgroundColor: '#1B4F32',
          },
        },
      },
      content: {
        base: {
          style: {
            width: '40px',
            height: '40px',
            fontWeight: '400',
          },
        },
        startEnd: {
          style: {
            width: '40px',
            height: '40px',
            color: '#fff',
            fontWeight: '500',
          },
        },
      },
    },
  ]

  const modelValue = defineModel() as any
  const modifiers = computed(() => ({
    range: props.range,
  }))

  /*
  watch(
    modelValue,
    (_new: any, _old: any) => {
      if (props.isRange) {
        _new.start = props.startFilter(_new.start)
        _new.end = props.endFilter(_new.end)
      } else {
        _new = props.filter(_new)
      }
    },
    {
      deep: true,
    },
  )
*/
  const rules = ref({
    hours: 12,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  })
</script>

<template>
  <Field type="text" :name="props.name">
    <DatePicker
      v-bind="$attrs"
      v-model="modelValue"
      :model-modifiers="modifiers"
      :popover="{ visibility: 'focus' }"
      trim-weeks
      :attributes="attr"
      :select-attribute="selectAttribute"
      :drag-attribute="selectAttribute"
      :columns="props.columns || ecolumns"
      :rules="rules"
    >
      <template #default="{ inputValue, inputEvents }">
        <input
          v-if="$props.range"
          :id="props.id"
          :name="props.name"
          :value="inputValue.start && inputValue.end ? inputValue.start + ' - ' + inputValue.end : ''"
          class="form-input rangepicker-input"
          :class="props.class"
          type="text"
          :disabled="$attrs.disabled as boolean"
          readonly
          :placeholder="props.placeholder"
          v-on="inputEvents.start"
        />
        <input
          v-else
          :id="props.id"
          :name="props.name"
          type="text"
          class="form-input date-picker-input"
          :class="props.class"
          :value="inputValue"
          :placeholder="props.placeholder"
          v-on="inputEvents"
        />
      </template>
    </DatePicker>
  </Field>
</template>

<style lang="scss" scoped>
  .datepicker {
    display: flex;
    &-input {
      width: 100%;
      height: 100%;
    }
  }
  .rangepicker {
    &-input {
      //width: auto;
    }
  }
</style>
<style lang="scss">
  @import 'v-calendar/dist/style.css';
  .vc {
    &-header {
      height: auto;
      padding: 1.25rem 1.25rem 0;
    }
    &-weeks {
      gap: 4px 0;
      padding: 1.25rem 1.25rem 1rem;
    }
    &-day {
      &-content {
        width: 40px;
        height: 40px;
      }
    }
    &-arrows {
      &-container {
        padding-top: 1.25rem;
      }
    }
  }
</style>
