<script lang="ts" setup>
  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps({
    errors: {
      type: [Object, String],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  })

  const modelValue = defineModel()
  //const inputValue = ref(props.modelValue)
</script>

<template>
  <label v-if="props.label" class="form-label">{{ props.label }}</label>
  <div class="form-radio-group">
    <template v-for="item in items as any" :key="item">
      <div class="form-radio-data">
        <input
          v-bind="$attrs"
          :id="item.id"
          v-model="modelValue"
          :name="props.name"
          class="form-radio"
          type="radio"
          :value="typeof item.value === 'boolean' ? (item.value ? true : false) : item.value || item.label"
        />
        <label class="form-radio-label" :for="item.id">{{ item.label }}</label>
      </div>
    </template>
  </div>
</template>
