<script lang="ts" setup>
  import { ref } from 'vue'

  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps({
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: (props: any) => props.id,
    },
    labelInside: {
      type: Boolean,
      default: false,
    },
  })

  const passwordType = ref('password')
  // See password
  function passwordTypeChanger() {
    if (passwordType.value === 'password') {
      passwordType.value = 'text'
    } else if (passwordType.value === 'text') {
      passwordType.value = 'password'
    }
  }
</script>

<template>
  <label v-if="props.label" class="form-label" :class="{ 'form-label-inside': props.labelInside }" :for="props.id">{{ props.label }}</label>
  <div class="form-group-password">
    <Field
      :id="props.id"
      :name="props.name"
      class="form-input"
      :type="passwordType"
      :label="props.label"
      :placeholder="props.placeholder"
      v-bind="$attrs"
      autocomplete="new-password"
    ></Field>
    <button class="form-password-type" type="button" @click="passwordTypeChanger">
      <icon v-if="passwordType === 'password'" name="eye" size="20" />
      <icon v-if="passwordType === 'text'" name="eye-off" size="20" />
    </button>
  </div>
</template>
