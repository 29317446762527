import { useUserStore } from '~/store/user'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const userStore = useUserStore()
  const patientGuideStore = usePatientGuideStore()
  const nuxtapp = useNuxtApp()

  /*
  if (to.meta?.title === 'patient-guide') {
    await patientGuideStore.setPatientGuide(nuxtapp.$i18n.locale.value)
    return navigateTo(patientGuideStore.firstPage ? useLocalePath()(`${to.fullPath}/` + patientGuideStore.firstPage) : '/')
  }
  */

  if (to.path !== from.path && process.client) {
    window.scrollBy({
      top: 0,
      behavior: 'smooth',
    })
  }

  if (to.params.categoryone) {
    const idxOne = userStore.getServices?.findIndex((item: any) => item?.slug === to.params.categoryone)
    if (!to.params.categorytwo && !to.params.categorythree) userStore.activeService = userStore.getServices?.[idxOne]
    if (to.params.categorytwo) {
      const idxTwo = userStore.getServices?.[idxOne]?.children?.findIndex((item: any) => item.slug === to.params.categorytwo)
      if (!to.params.categorythree) userStore.activeService = userStore.getServices?.[idxOne]?.children?.[idxTwo]
      if (to.params.categorythree) {
        const idxThree = userStore.getServices?.[idxOne]?.children?.[idxTwo]?.children?.findIndex((item: any) => item?.slug === to.params.categorythree)
        userStore.activeService = userStore.getServices?.[idxOne]?.children?.[idxTwo]?.children?.[idxThree]
      }
    }
  }

  if (typeof window !== 'undefined') {
    if (document.body.offsetWidth < 1200) {
      document.body?.classList.remove('active')
    }
  }
})
