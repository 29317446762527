const toString = Object.prototype.toString

export function is(val: unknown, type: string) {
  return toString.call(val) === `[object ${type}]`
}

export function isDef<T = unknown>(val?: T): val is T {
  return typeof val !== 'undefined'
}

export function isUnDef<T = unknown>(val?: T): val is T {
  return !isDef(val)
}

export function isObject(val: any): val is Record<any, any> {
  return val !== null && is(val, 'Object')
}

export function isEmpty<T = unknown>(val: T): val is T {
  if (isArray(val) || isString(val)) {
    return val.length === 0
  }

  if (val instanceof Map || val instanceof Set) {
    return val.size === 0
  }

  if (isObject(val)) {
    return Object.keys(val).length === 0
  }

  return false
}

export function isDate(val: unknown): val is Date {
  return is(val, 'Date')
}

export function isNull(val: unknown): val is null {
  return val === null
}

export function isNullAndUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) && isNull(val)
}

export function isNullOrUnDef(val: unknown): val is null | undefined {
  return isUnDef(val) || isNull(val)
}

export function isNumber(val: unknown): val is number {
  return is(val, 'Number')
}

export function isPromise<T = any>(val: unknown): val is Promise<T> {
  return is(val, 'Promise') && isObject(val) && isFunction(val.then) && isFunction(val.catch)
}

export function isString(val: unknown): val is string {
  return is(val, 'String')
}

export function isFunction(val: unknown): val is Function {
  return typeof val === 'function'
}

export function isBoolean(val: unknown): val is boolean {
  return is(val, 'Boolean')
}

export function isRegExp(val: unknown): val is RegExp {
  return is(val, 'RegExp')
}

export function isArray(val: any): val is Array<any> {
  return val && Array.isArray(val)
}

export function isWindow(val: any): val is Window {
  return typeof window !== 'undefined' && is(val, 'Window')
}

export function isElement(val: unknown): val is Element {
  return isObject(val) && !!val.tagName
}

export function isMap(val: unknown): val is Map<any, any> {
  return is(val, 'Map')
}

export const isServer = typeof window === 'undefined'

export const isClient = !isServer

export function isUrl(path: string): boolean {
  const reg =
    /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/
  return reg.test(path)
}

export const isObjectsEqual = function (obj1: Record<any, any>, obj2: Record<any, any>, without?: string | string[]) {
  const omit = (ks: any) => (o: any) => Object.fromEntries(Object.entries(o).filter(([k]) => !ks.includes(k)))

  let withoutQuantity = omit([without])
  Array.isArray(without) ? (withoutQuantity = omit(without)) : (withoutQuantity = omit([without]))
  const equal = (a: any, b: any) => {
    const ae = Object.entries(a)
    const be = Object.entries(b)
    return ae.length === be.length && ae.every(([k, x]) => x === b[k])
  }
  return without ? equal(withoutQuantity(obj1), withoutQuantity(obj2)) : equal(obj1, obj2)
}

export const isObjectEmpty = function (val: any) {
  return Object.keys(val).length === 0
}

// isEqual function for generic comparison
export function isEqual(val1: any, val2: any): boolean {
  const isObject = (value: any): value is Record<string, any> => typeof value === 'object' && value !== null
  const isArray = Array.isArray

  if (isObject(val1) && isObject(val2)) {
    if (isArray(val1) && isArray(val2)) {
      return isArraysEqual(val1, val2)
    }
    return isObjectsEqual(val1, val2)
  }

  return val1 === val2
}
