<script lang="ts" setup>
  import { useSlots } from 'vue'

  const slots = useSlots()
  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps({
    errors: {
      type: [String, Object],
      default: () => '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: (props: any) => props.id,
    },
    type: {
      type: String,
      default: 'text',
    },
    labelInside: {
      type: Boolean,
      default: false,
    },
  })
</script>

<template>
  <label v-if="props.label && showLabel" class="form-label" :class="{ 'form-label-inside': props.labelInside }" :for="props.id">
    {{ props.label }}
  </label>
  <div class="form-input-group" :class="{ 'with-suffix': slots.suffix }">
    <span v-if="slots.prefix" class="form-input-prefix">
      <slot name="prefix" />
    </span>
    <Field
      v-bind="$attrs"
      :id="props.id"
      :as="props.type === 'textarea' ? 'textarea' : ''"
      :name="props.name"
      class="form-input"
      :class="{ 'with-suffix': slots.suffix }"
      :type="props.type"
      :label="props.label"
      :placeholder="props.placeholder"
      @click="$emit('inputClick')"
    />
    <span v-if="slots.suffix" class="form-input-suffix">
      <slot name="suffix" />
    </span>
  </div>
</template>
