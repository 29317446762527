import ResizeObserver from 'resize-observer-polyfill'

export const getCmsUrl = function (_url: string) {
  const runtimeConfig = useRuntimeConfig()
  if (_url) return runtimeConfig.public.strapi.url + _url
  return ''
}
export const formatDate = function (inputDate: any) {
  const options = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'UTC' } as any
  const formattedDate = new Date(inputDate).toLocaleDateString(undefined, options)
  return formattedDate
}
export const firstCharUp = function (str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function replaceAfterLastSlash(url: string, newPart: string = '') {
  var parts = url.split('/')
  parts.pop() // Removes the part after the last slash
  return parts.join('/') + newPart ? '/' + newPart : ''
}

export function removeLastLocation(path: any) {
  // Eğer path boşsa veya sadece '/' karakterinden oluşuyorsa, boş string döndür
  if (path === '/' || path === '') {
    return ''
  }

  // Eğer path '/' karakteriyle bitiyorsa, bu karakteri kaldır
  if (path.endsWith('/')) {
    return path.slice(0, -1)
  }

  // Son '/' karakterinin konumunu bul
  const lastIndex = path.lastIndexOf('/')

  // Eğer '/' karakteri bulunamazsa, path'i olduğu gibi döndür
  if (lastIndex === -1) {
    return path
  }

  // '/' karakterinin konumuna göre, path'i uygun şekilde kırp ve döndür
  return path.slice(0, lastIndex)
}

export const stringToSlug = function (str: string) {
  str = String(str).toString()
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  interface Swaps {
    [key: string]: string[]
  }
  // remove accents, swap ñ for n, etc
  const swaps: Swaps = {
    '0': ['°', '₀', '۰', '０'],
    '1': ['¹', '₁', '۱', '１'],
    '2': ['²', '₂', '۲', '２'],
    '3': ['³', '₃', '۳', '３'],
    '4': ['⁴', '₄', '۴', '٤', '４'],
    '5': ['⁵', '₅', '۵', '٥', '５'],
    '6': ['⁶', '₆', '۶', '٦', '６'],
    '7': ['⁷', '₇', '۷', '７'],
    '8': ['⁸', '₈', '۸', '８'],
    '9': ['⁹', '₉', '۹', '９'],
    a: [
      'à',
      'á',
      'ả',
      'ã',
      'ạ',
      'ă',
      'ắ',
      'ằ',
      'ẳ',
      'ẵ',
      'ặ',
      'â',
      'ấ',
      'ầ',
      'ẩ',
      'ẫ',
      'ậ',
      'ā',
      'ą',
      'å',
      'α',
      'ά',
      'ἀ',
      'ἁ',
      'ἂ',
      'ἃ',
      'ἄ',
      'ἅ',
      'ἆ',
      'ἇ',
      'ᾀ',
      'ᾁ',
      'ᾂ',
      'ᾃ',
      'ᾄ',
      'ᾅ',
      'ᾆ',
      'ᾇ',
      'ὰ',
      'ά',
      'ᾰ',
      'ᾱ',
      'ᾲ',
      'ᾳ',
      'ᾴ',
      'ᾶ',
      'ᾷ',
      'а',
      'أ',
      'အ',
      'ာ',
      'ါ',
      'ǻ',
      'ǎ',
      'ª',
      'ა',
      'अ',
      'ا',
      'ａ',
      'ä',
    ],
    b: ['б', 'β', 'ب', 'ဗ', 'ბ', 'ｂ'],
    c: ['ç', 'ć', 'č', 'ĉ', 'ċ', 'ｃ'],
    d: ['ď', 'ð', 'đ', 'ƌ', 'ȡ', 'ɖ', 'ɗ', 'ᵭ', 'ᶁ', 'ᶑ', 'д', 'δ', 'د', 'ض', 'ဍ', 'ဒ', 'დ', 'ｄ'],
    e: [
      'é',
      'è',
      'ẻ',
      'ẽ',
      'ẹ',
      'ê',
      'ế',
      'ề',
      'ể',
      'ễ',
      'ệ',
      'ë',
      'ē',
      'ę',
      'ě',
      'ĕ',
      'ė',
      'ε',
      'έ',
      'ἐ',
      'ἑ',
      'ἒ',
      'ἓ',
      'ἔ',
      'ἕ',
      'ὲ',
      'έ',
      'е',
      'ё',
      'э',
      'є',
      'ə',
      'ဧ',
      'ေ',
      'ဲ',
      'ე',
      'ए',
      'إ',
      'ئ',
      'ｅ',
    ],
    f: ['ф', 'φ', 'ف', 'ƒ', 'ფ', 'ｆ'],
    g: ['ĝ', 'ğ', 'ġ', 'ģ', 'г', 'ґ', 'γ', 'ဂ', 'გ', 'گ', 'ｇ'],
    h: ['ĥ', 'ħ', 'η', 'ή', 'ح', 'ه', 'ဟ', 'ှ', 'ჰ', 'ｈ'],
    i: [
      'í',
      'ì',
      'ỉ',
      'ĩ',
      'ị',
      'î',
      'ï',
      'ī',
      'ĭ',
      'į',
      'ı',
      'ι',
      'ί',
      'ϊ',
      'ΐ',
      'ἰ',
      'ἱ',
      'ἲ',
      'ἳ',
      'ἴ',
      'ἵ',
      'ἶ',
      'ἷ',
      'ὶ',
      'ί',
      'ῐ',
      'ῑ',
      'ῒ',
      'ΐ',
      'ῖ',
      'ῗ',
      'і',
      'ї',
      'и',
      'ဣ',
      'ိ',
      'ီ',
      'ည်',
      'ǐ',
      'ი',
      'इ',
      'ی',
      'ｉ',
    ],
    j: ['ĵ', 'ј', 'Ј', 'ჯ', 'ج', 'ｊ'],
    k: ['ķ', 'ĸ', 'к', 'κ', 'Ķ', 'ق', 'ك', 'က', 'კ', 'ქ', 'ک', 'ｋ'],
    l: ['ł', 'ľ', 'ĺ', 'ļ', 'ŀ', 'л', 'λ', 'ل', 'လ', 'ლ', 'ｌ'],
    m: ['м', 'μ', 'م', 'မ', 'მ', 'ｍ'],
    n: ['ñ', 'ń', 'ň', 'ņ', 'ŉ', 'ŋ', 'ν', 'н', 'ن', 'န', 'ნ', 'ｎ'],
    o: [
      'ó',
      'ò',
      'ỏ',
      'õ',
      'ọ',
      'ô',
      'ố',
      'ồ',
      'ổ',
      'ỗ',
      'ộ',
      'ơ',
      'ớ',
      'ờ',
      'ở',
      'ỡ',
      'ợ',
      'ø',
      'ō',
      'ő',
      'ŏ',
      'ο',
      'ὀ',
      'ὁ',
      'ὂ',
      'ὃ',
      'ὄ',
      'ὅ',
      'ὸ',
      'ό',
      'о',
      'و',
      'θ',
      'ို',
      'ǒ',
      'ǿ',
      'º',
      'ო',
      'ओ',
      'ｏ',
      'ö',
    ],
    p: ['п', 'π', 'ပ', 'პ', 'پ', 'ｐ'],
    q: ['ყ', 'ｑ'],
    r: ['ŕ', 'ř', 'ŗ', 'р', 'ρ', 'ر', 'რ', 'ｒ'],
    s: ['ś', 'š', 'ş', 'с', 'σ', 'ș', 'ς', 'س', 'ص', 'စ', 'ſ', 'ს', 'ｓ'],
    t: ['ť', 'ţ', 'т', 'τ', 'ț', 'ت', 'ط', 'ဋ', 'တ', 'ŧ', 'თ', 'ტ', 'ｔ'],
    u: [
      'ú',
      'ù',
      'ủ',
      'ũ',
      'ụ',
      'ư',
      'ứ',
      'ừ',
      'ử',
      'ữ',
      'ự',
      'û',
      'ū',
      'ů',
      'ű',
      'ŭ',
      'ų',
      'µ',
      'у',
      'ဉ',
      'ု',
      'ူ',
      'ǔ',
      'ǖ',
      'ǘ',
      'ǚ',
      'ǜ',
      'უ',
      'उ',
      'ｕ',
      'ў',
      'ü',
    ],
    v: ['в', 'ვ', 'ϐ', 'ｖ'],
    w: ['ŵ', 'ω', 'ώ', 'ဝ', 'ွ', 'ｗ'],
    x: ['χ', 'ξ', 'ｘ'],
    y: ['ý', 'ỳ', 'ỷ', 'ỹ', 'ỵ', 'ÿ', 'ŷ', 'й', 'ы', 'υ', 'ϋ', 'ύ', 'ΰ', 'ي', 'ယ', 'ｙ'],
    z: ['ź', 'ž', 'ż', 'з', 'ζ', 'ز', 'ဇ', 'ზ', 'ｚ'],
    aa: ['ع', 'आ', 'آ'],
    ae: ['æ', 'ǽ'],
    ai: ['ऐ'],
    ch: ['ч', 'ჩ', 'ჭ', 'چ'],
    dj: ['ђ', 'đ'],
    dz: ['џ', 'ძ'],
    ei: ['ऍ'],
    gh: ['غ', 'ღ'],
    ii: ['ई'],
    ij: ['ĳ'],
    kh: ['х', 'خ', 'ხ'],
    lj: ['љ'],
    nj: ['њ'],
    oe: ['ö', 'œ', 'ؤ'],
    oi: ['ऑ'],
    oii: ['ऒ'],
    ps: ['ψ'],
    sh: ['ш', 'შ', 'ش'],
    shch: ['щ'],
    ss: ['ß'],
    sx: ['ŝ'],
    th: ['þ', 'ϑ', 'ث', 'ذ', 'ظ'],
    ts: ['ц', 'ც', 'წ'],
    ue: ['ü'],
    uu: ['ऊ'],
    ya: ['я'],
    yu: ['ю'],
    zh: ['ж', 'ჟ', 'ژ'],
    '(c)': ['©'],
    A: [
      'Á',
      'À',
      'Ả',
      'Ã',
      'Ạ',
      'Ă',
      'Ắ',
      'Ằ',
      'Ẳ',
      'Ẵ',
      'Ặ',
      'Â',
      'Ấ',
      'Ầ',
      'Ẩ',
      'Ẫ',
      'Ậ',
      'Å',
      'Ā',
      'Ą',
      'Α',
      'Ά',
      'Ἀ',
      'Ἁ',
      'Ἂ',
      'Ἃ',
      'Ἄ',
      'Ἅ',
      'Ἆ',
      'Ἇ',
      'ᾈ',
      'ᾉ',
      'ᾊ',
      'ᾋ',
      'ᾌ',
      'ᾍ',
      'ᾎ',
      'ᾏ',
      'Ᾰ',
      'Ᾱ',
      'Ὰ',
      'Ά',
      'ᾼ',
      'А',
      'Ǻ',
      'Ǎ',
      'Ａ',
      'Ä',
    ],
    B: ['Б', 'Β', 'ब', 'Ｂ'],
    C: ['Ç', 'Ć', 'Č', 'Ĉ', 'Ċ', 'Ｃ'],
    D: ['Ď', 'Ð', 'Đ', 'Ɖ', 'Ɗ', 'Ƌ', 'ᴅ', 'ᴆ', 'Д', 'Δ', 'Ｄ'],
    E: [
      'É',
      'È',
      'Ẻ',
      'Ẽ',
      'Ẹ',
      'Ê',
      'Ế',
      'Ề',
      'Ể',
      'Ễ',
      'Ệ',
      'Ë',
      'Ē',
      'Ę',
      'Ě',
      'Ĕ',
      'Ė',
      'Ε',
      'Έ',
      'Ἐ',
      'Ἑ',
      'Ἒ',
      'Ἓ',
      'Ἔ',
      'Ἕ',
      'Έ',
      'Ὲ',
      'Е',
      'Ё',
      'Э',
      'Є',
      'Ə',
      'Ｅ',
    ],
    F: ['Ф', 'Φ', 'Ｆ'],
    G: ['Ğ', 'Ġ', 'Ģ', 'Г', 'Ґ', 'Γ', 'Ｇ'],
    H: ['Η', 'Ή', 'Ħ', 'Ｈ'],
    I: [
      'Í',
      'Ì',
      'Ỉ',
      'Ĩ',
      'Ị',
      'Î',
      'Ï',
      'Ī',
      'Ĭ',
      'Į',
      'İ',
      'Ι',
      'Ί',
      'Ϊ',
      'Ἰ',
      'Ἱ',
      'Ἳ',
      'Ἴ',
      'Ἵ',
      'Ἶ',
      'Ἷ',
      'Ῐ',
      'Ῑ',
      'Ὶ',
      'Ί',
      'И',
      'І',
      'Ї',
      'Ǐ',
      'ϒ',
      'Ｉ',
    ],
    J: ['Ｊ'],
    K: ['К', 'Κ', 'Ｋ'],
    L: ['Ĺ', 'Ł', 'Л', 'Λ', 'Ļ', 'Ľ', 'Ŀ', 'ल', 'Ｌ'],
    M: ['М', 'Μ', 'Ｍ'],
    N: ['Ń', 'Ñ', 'Ň', 'Ņ', 'Ŋ', 'Н', 'Ν', 'Ｎ'],
    O: [
      'Ó',
      'Ò',
      'Ỏ',
      'Õ',
      'Ọ',
      'Ô',
      'Ố',
      'Ồ',
      'Ổ',
      'Ỗ',
      'Ộ',
      'Ơ',
      'Ớ',
      'Ờ',
      'Ở',
      'Ỡ',
      'Ợ',
      'Ø',
      'Ō',
      'Ő',
      'Ŏ',
      'Ο',
      'Ό',
      'Ὀ',
      'Ὁ',
      'Ὂ',
      'Ὃ',
      'Ὄ',
      'Ὅ',
      'Ὸ',
      'Ό',
      'О',
      'Θ',
      'Ө',
      'Ǒ',
      'Ǿ',
      'Ｏ',
      'Ö',
    ],
    P: ['П', 'Π', 'Ｐ'],
    Q: ['Ｑ'],
    R: ['Ř', 'Ŕ', 'Р', 'Ρ', 'Ŗ', 'Ｒ'],
    S: ['Ş', 'Ŝ', 'Ș', 'Š', 'Ś', 'С', 'Σ', 'Ｓ'],
    T: ['Ť', 'Ţ', 'Ŧ', 'Ț', 'Т', 'Τ', 'Ｔ'],
    U: ['Ú', 'Ù', 'Ủ', 'Ũ', 'Ụ', 'Ư', 'Ứ', 'Ừ', 'Ử', 'Ữ', 'Ự', 'Û', 'Ū', 'Ů', 'Ű', 'Ŭ', 'Ų', 'У', 'Ǔ', 'Ǖ', 'Ǘ', 'Ǚ', 'Ǜ', 'Ｕ', 'Ў', 'Ü'],
    V: ['В', 'Ｖ'],
    W: ['Ω', 'Ώ', 'Ŵ', 'Ｗ'],
    X: ['Χ', 'Ξ', 'Ｘ'],
    Y: ['Ý', 'Ỳ', 'Ỷ', 'Ỹ', 'Ỵ', 'Ÿ', 'Ῠ', 'Ῡ', 'Ὺ', 'Ύ', 'Ы', 'Й', 'Υ', 'Ϋ', 'Ŷ', 'Ｙ'],
    Z: ['Ź', 'Ž', 'Ż', 'З', 'Ζ', 'Ｚ'],
    AE: ['Æ', 'Ǽ'],
    Ch: ['Ч'],
    Dj: ['Ђ'],
    Dz: ['Џ'],
    Gx: ['Ĝ'],
    Hx: ['Ĥ'],
    Ij: ['Ĳ'],
    Jx: ['Ĵ'],
    Kh: ['Х'],
    Lj: ['Љ'],
    Nj: ['Њ'],
    Oe: ['Œ'],
    Ps: ['Ψ'],
    Sh: ['Ш'],
    Shch: ['Щ'],
    Ss: ['ẞ'],
    Th: ['Þ'],
    Ts: ['Ц'],
    Ya: ['Я'],
    Yu: ['Ю'],
    Zh: ['Ж'],
  }

  Object.keys(swaps).forEach((swap) => {
    swaps[swap].forEach((s: string) => {
      str = str.replace(new RegExp(s, 'g'), swap)
    })
  })
  return str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, '')
}
export const generateServiceRoute = function (inputData: any, locale?: any) {
  const result = [] as any
  const tree = [] as any
  const contentTree = [] as any
  inputData?.data?.forEach((item: any) => {
    const newItem = {
      id: item.id.toString(),
      name: item?.attributes?.name,
      slug: item?.attributes?.slug,
      link: '/' + item?.attributes?.slug,
      show: true,
      active: false,
      children: [],
      level: 1,
      parentId: null,
      childrenIsContent: false,
      localizations: {},
      slugs: [item?.attributes?.slug],
    } as any
    newItem.localizations[locale] = { slug: newItem.slug, link: newItem.link }
    item?.attributes?.localizations?.data?.forEach((_a: any) => {
      newItem.slugs.push(_a?.attributes?.slug)
      newItem.localizations[_a?.attributes?.locale] = { slug: _a?.attributes?.slug, link: '/' + _a?.attributes?.slug }
    })

    const content1thGenerator = function (content: any, origin?: any) {
      content?.attributes?.components?.forEach((types: any) => {
        const newChildOne = {
          id: types.id.toString(),
          name: types.name,
          slug: stringToSlug(types.name),
          link: '/' + item.attributes?.slug + '#' + stringToSlug(types.name),
          show: content?.attributes?.showOnMenu,
          active: false,
          children: [],
          parentId: item.id.toString(),
          isContent: true,
          localizations: {},
        } as any
        newItem.childrenIsContent = true
        newItem.children.push(newChildOne)
      })
    }
    if (item?.attributes?.content && item?.attributes?.content?.data) {
      content1thGenerator(item?.attributes?.content.data)
    }
    if (item?.attributes?.category_twos && item?.attributes?.category_twos?.data?.length > 0) {
      item?.attributes?.category_twos?.data?.forEach((categoryTwo: any) => {
        const newCategoryTwo = {
          id: categoryTwo?.id.toString(),
          name: categoryTwo?.attributes?.name,
          slug: categoryTwo?.attributes?.slug,
          link: newItem.link + '/' + categoryTwo?.attributes?.slug,
          show: true,
          active: false,
          children: [],
          level: 2,
          parentId: item.id.toString(),
          childrenIsContent: false,
          localizations: {},
          slugs: [categoryTwo?.attributes?.slug],
        } as any

        newCategoryTwo.localizations[locale] = { slug: newCategoryTwo.slug, link: newCategoryTwo.link }
        categoryTwo?.attributes?.localizations?.data?.forEach((_a: any) => {
          newCategoryTwo.slugs.push(_a?.attributes?.slug)
          newCategoryTwo.localizations[_a?.attributes?.locale] = {
            slug: _a?.attributes?.slug,
            link: newItem.localizations[_a?.attributes?.locale].link + '/' + _a?.attributes?.slug,
          }
        })

        const content2thGenerator = function (content: any, origin?: any) {
          content?.attributes?.components?.forEach((types: any) => {
            const newCategoryThree = {
              id: types.id.toString(),
              name: types.name,
              slug: stringToSlug(types.name),
              link: newCategoryTwo.link + '#' + stringToSlug(types.name),
              show: content?.attributes?.showOnMenu,
              active: false,
              children: [],
              parentId: categoryTwo.id.toString(),
              isContent: true,
            } as any
            newItem.childrenIsContent = false
            newCategoryTwo.childrenIsContent = true
            contentTree.push(newCategoryThree)
            newCategoryTwo.children.push(newCategoryThree)
          })
        }
        if (categoryTwo?.attributes?.content && categoryTwo?.attributes?.content?.data) {
          content2thGenerator(categoryTwo?.attributes?.content.data)
        }
        if (categoryTwo?.attributes?.category_threes && categoryTwo?.attributes?.category_threes?.data?.length > 0) {
          categoryTwo?.attributes?.category_threes?.data?.forEach((categoryThree: any) => {
            const newCategoryThree = {
              id: categoryThree.id.toString(),
              name: categoryThree?.attributes?.name,
              slug: categoryThree?.attributes?.slug,
              link: newCategoryTwo.link + '/' + categoryThree?.attributes?.slug,
              show: true,
              active: false,
              children: [],
              level: 3,
              parentId: categoryTwo.id.toString(),
              childrenIsContent: true,
              localizations: {},
              slugs: [categoryThree?.attributes?.slug],
            } as any
            newCategoryThree.localizations[locale] = { slug: newCategoryThree.slug, link: newCategoryThree.link }
            categoryThree?.attributes?.localizations?.data?.forEach((_a: any) => {
              newCategoryThree.slugs.push(_a?.attributes?.slug)
              newCategoryThree.localizations[_a?.attributes?.locale] = {
                slug: _a?.attributes?.slug,
                link: newCategoryTwo.localizations[_a?.attributes?.locale].link + '/' + _a?.attributes?.slug,
              }
            })

            const category3thGenerator = function (content: any, origin?: any) {
              content?.attributes?.components?.forEach((types: any) => {
                const newContent = {
                  id: types.id.toString(),
                  name: types.name,
                  slug: stringToSlug(types.name),
                  link: newCategoryThree.link + '#' + stringToSlug(types.name),
                  parentId: categoryThree.id.toString(),
                  show: false,
                  active: false,
                  isContent: true,
                } as any
                newCategoryTwo.childrenIsContent = false
                newCategoryThree.children.push(newContent)
                contentTree.push(newContent)
              })
            }

            if (categoryThree?.attributes?.content && categoryThree?.attributes?.content?.data) {
              category3thGenerator(categoryThree?.attributes?.content.data)
            }

            tree.push(newCategoryThree)
            newCategoryTwo.children.push(newCategoryThree)
          })
        } else {
          newCategoryTwo.childrenIsContent = true
        }

        tree.push(newCategoryTwo)
        newItem.children.push(newCategoryTwo)
      })
    } else {
      newItem.childrenIsContent = true
    }
    tree.push(newItem)
    result.push(newItem)
  })
  return { services: result, tree, treeWithContent: [...tree, ...contentTree] }
}

export const generatePatientGuideRouteForSitemap = function (data: any) {
  const patientGuide = [] as any

  data.forEach((item: any) => {
    const obj = {
      slug: '/' + item?.attributes?.slug,
      firstPage: item?.attributes?.firstPage || false,
    } as any

    patientGuide.push(obj)

    item?.attributes?.categories?.data?.forEach((subitem: any) => {
      const obj2 = {
        slug: obj.slug + '/' + subitem?.attributes?.slug,
        firstPage: item?.attributes?.firstPage || false,
      } as any
      patientGuide.push(obj2)
    })
  })
  return patientGuide
}

const isServer = typeof window === 'undefined'

/* istanbul ignore next */
function resizeHandler(entries: any[]) {
  for (const entry of entries) {
    const listeners = entry.target.__resizeListeners__ || []
    if (listeners.length) {
      listeners.forEach((fn: () => any) => {
        fn()
      })
    }
  }
}

/* istanbul ignore next */
export function addResizeListener(element: any, fn: () => any) {
  if (isServer) return
  if (!element.__resizeListeners__) {
    element.__resizeListeners__ = []
    element.__ro__ = new ResizeObserver(resizeHandler)
    element.__ro__.observe(element)
  }
  element.__resizeListeners__.push(fn)
}

/* istanbul ignore next */
export function removeResizeListener(element: any, fn: () => any) {
  if (!element || !element.__resizeListeners__) return
  element.__resizeListeners__.splice(element.__resizeListeners__.indexOf(fn), 1)
  if (!element.__resizeListeners__.length) {
    element.__ro__.disconnect()
  }
}

export function triggerWindowResize() {
  const event = document.createEvent('HTMLEvents')
  event.initEvent('resize', true, true)
  ;(event as any).eventType = 'message'
  window.dispatchEvent(event)
}

export function deepMerge<T = any>(src: any = {}, ...targets: Record<any, any>[]): T {
  for (const target of targets) {
    if (isObject(target)) {
      for (const key in target) {
        if (target.hasOwnProperty(key)) {
          src[key] = isObject(src[key])
            ? deepMerge(src[key], target[key])
            : target[key] === undefined
              ? src.hasOwnProperty(key)
                ? (src[key] = target[key])
                : null
              : (src[key] = target[key])
        }
      }
    }
  }
  return src
}

/**
 * Checks if two values are deeply equal.
 * @param {*} a - The first value to compare.
 * @param {*} b - The second value to compare.
 * @returns {boolean} - True if the values are deeply equal, false otherwise.
 */
export function deepEqual(a: any, b: any) {
  // Return true if both values are exactly the same
  if (a === b) {
    return true
  }

  // Return false if either value is null or undefined
  if (a === null || a === undefined || b === null || b === undefined) {
    return false
  }

  // Return false if the data types of the values are different
  if (typeof a !== typeof b) {
    return false
  }

  // If both values are arrays
  if (Array.isArray(a)) {
    // Return false if the lengths of the arrays are different
    if (a.length !== b.length) {
      return false
    }

    // Recursively compare the elements of the arrays
    for (let i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) {
        return false
      }
    }

    return true
  }

  // If both values are objects
  if (typeof a === 'object') {
    const keysA = Object.keys(a)
    const keysB = Object.keys(b)

    // Return false if the number of properties in the objects is different
    if (keysA.length !== keysB.length) {
      return false
    }

    // Recursively compare the properties of the objects
    for (const key of keysA) {
      if (!deepEqual(a[key], b[key])) {
        return false
      }
    }

    return true
  }

  // Values cannot be compared in other cases, return false
  return false
}

export function filterObjectWithKeys(obj: any, specialKeys: any) {
  const result = {} as any

  for (const key in obj) {
    if (!specialKeys.includes(key) && obj[key] !== null) {
      result[key] = obj[key]
    }
  }

  return result
}
