<script lang="ts" setup>
  import { ref, watch } from 'vue'

  type IProps = {
    items: any
    defaultOpen?: boolean
    firstActive?: boolean
    icon?: string
    onlyOne?: boolean
  }

  const props = withDefaults(defineProps<IProps>(), {
    defaultOpen: undefined,
    firstActive: true,
    icon: 'arrow',
    onlyOne: false,
  })

  const setVal = function () {
    const items = JSON.parse(JSON.stringify(props.items))
    items.forEach((item: any, idx: any) => {
      if (props.firstActive && idx === 0) item.active = true
      if (props.defaultOpen !== undefined) item.active = props.defaultOpen
      return (item.active = item?.active !== undefined ? item?.active : false)
    })
    return items
  }
  const newItems = ref(setVal())

  watch(
    () => props.items,
    () => {
      newItems.value = setVal()
    },
  )

  const modelValue = defineModel() as any
  watch(newItems, () => {
    modelValue.value = newItems.value
  })

  const setActive = function (item: any) {
    const bool = item.active
    if (props.onlyOne) {
      newItems.value.forEach((accordionItem: any) => {
        accordionItem.active = false
      })
    }

    item.active = !bool
  }
</script>

<template>
  <div v-if="newItems.length" class="accordion-root">
    <template v-for="item in newItems as any" :key="item?.label">
      <div class="accordion-item" :class="{ active: item?.active }">
        <div class="accordion-header" :aria-label="item?.label" @click="setActive(item)">
          <div class="accordion-title">
            <slot name="header" :item="item">
              <span v-html="item.label"></span>
            </slot>
          </div>
          <figure v-if="icon" class="accordion-figure" :class="[icon, { active: item?.active }]">
            <img v-if="icon === 'arrow'" class="accordion-img" src="/images/icons/arrow-down-angle.svg" alt="arrow" loading="lazy" />
          </figure>
        </div>
        <div v-toggle-slide="item.active" class="accordion-body">
          <slot :name="item.slot || 'default'" :item="item">
            <div class="accordion-content" v-html="item.content"></div>
          </slot>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .accordion {
    &-item {
      border-radius: 4px;
      border-bottom: 1px solid #ececec;

      &.active {
        .accordion-header {
          //padding: 1rem 1.5rem;
        }
      }

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      font-size: 15px;
      padding: 0.5rem 1.5rem;
      cursor: pointer;
    }
    &-main {
      flex: 1 1 auto;
    }
    &-title {
      flex: 1 1 100%;
      color: #272d27;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    &-icon {
      flex: 0 0 auto;
    }

    &-content {
      padding: 1.5rem 1.5rem 2rem;
    }

    &-figure {
      flex: 0 0 auto;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;

      &.plus {
        &::after {
          position: absolute;
          content: '';
          inset: 0;
          width: 2px;
          height: 16px;
          margin: auto;
          background-color: #000;
          opacity: 0;
        }

        &::before {
          position: absolute;
          content: '';
          inset: 0;
          width: 16px;
          height: 2px;
          margin: auto;
          background-color: #000;
        }

        &.active {
          &::after {
            opacity: 1;
          }
        }
      }

      &.arrow {
        transition: 0.3s;

        &.active {
          transform: rotate(-180deg);
          transform-origin: center 50%;
        }
      }
    }

    &-img {
      width: 15px;
    }
  }
</style>
