<script lang="ts" setup>
  import { useSlots } from 'vue'

  const slots = useSlots()
  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps({
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: (props: any) => props.id,
    },
    type: {
      type: String,
      default: 'text',
    },
    labelInside: {
      type: Boolean,
      default: false,
    },
  })
</script>

<template>
  <label v-if="props.label" class="form-label" :class="{ 'form-label-inside': props.labelInside }" :for="props.id">{{ props.label }}</label>
  <div class="form-input-group" :class="{ 'with-suffix': slots.action }">
    <span v-if="slots.indicators" class="form-input-indicators">
      <slot name="indicator" />
    </span>
    <Field
      v-bind="$attrs"
      :id="props.id"
      :as="props.type === 'textarea' ? 'textarea' : ''"
      :name="props.name"
      class="form-input"
      :class="{ 'with-suffix': slots.suffix }"
      :type="props.type"
      :label="props.label"
      :placeholder="props.placeholder"
      @click="$emit('inputClick')"
    />
    <span v-if="slots.action" class="form-input-suffix">
      <slot name="action" />
    </span>
  </div>
</template>
