import revive_payload_client_GOBZV3GprU from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AmtTYyMMUJ from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ppMv78TPiW from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_zswkjTCtPX from "/home/node/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.20.0_vite@5.4.8_@types+node@22.7.4_sass@1.79._prsprl2dttthvad5a25zgllqbu/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_TVIQfwVJw6 from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_wa3m6bBzg3 from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bT762zMHmt from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_KWb4EaY1t5 from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_nqjxKZVIVO from "/home/node/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.20.0_vue@3.5.11_webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/node/app/.nuxt/components.plugin.mjs";
import prefetch_client_xm8TJuQXmF from "/home/node/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@9.11.1_jiti@1.21.6__ioredis@5.4.1_73j73r3mn2c72rhjilnat6dgbu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_748HGyQ1ei from "/home/node/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.20.0_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import sentry_client_AFcFXpbGHJ from "/home/node/app/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._nhqpk45ey4udvf55eb4x6pc5hi/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/node/app/.nuxt/sentry-client-config.mjs";
import nuxt_plugin_Cer1veXjM8 from "/home/node/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.20.0_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import strapi_2c80hUrKT4 from "/home/node/app/node_modules/.pnpm/@nuxtjs+strapi@1.12.0_magicast@0.3.5_rollup@4.20.0_webpack-sources@3.2.3/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.mjs";
import switch_locale_path_ssr_3yhCNgh6yG from "/home/node/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.20.0_vue@3.5.11_webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_L8RXPcXyde from "/home/node/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.5_magicast@0.3.5_rollup@4.20.0_vue@3.5.11_webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import bootstrap_client_Uyd73t1ylZ from "/home/node/app/plugins/bootstrap.client.ts";
import closable_CQvMsMRv9U from "/home/node/app/plugins/closable.ts";
import components_m1q6StQDkO from "/home/node/app/plugins/components.ts";
import emitter_eaMgM0oZTb from "/home/node/app/plugins/emitter.ts";
import maska_UHaKf2z1iQ from "/home/node/app/plugins/maska.ts";
import setter_OI6M1XQYgl from "/home/node/app/plugins/setter.ts";
import strapi_lgLLCZrkNA from "/home/node/app/plugins/strapi.ts";
import toggle_slide_uu97DNS2qu from "/home/node/app/plugins/toggle-slide.ts";
import vee_validate_KcKlKmvCrJ from "/home/node/app/plugins/vee-validate.ts";
export default [
  revive_payload_client_GOBZV3GprU,
  unhead_AmtTYyMMUJ,
  router_ppMv78TPiW,
  _0_siteConfig_zswkjTCtPX,
  payload_client_TVIQfwVJw6,
  navigation_repaint_client_wa3m6bBzg3,
  check_outdated_build_client_bT762zMHmt,
  chunk_reload_client_KWb4EaY1t5,
  plugin_vue3_nqjxKZVIVO,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xm8TJuQXmF,
  plugin_client_748HGyQ1ei,
  sentry_client_AFcFXpbGHJ,
  sentry_client_config_o34nk2sJbg,
  nuxt_plugin_Cer1veXjM8,
  strapi_2c80hUrKT4,
  switch_locale_path_ssr_3yhCNgh6yG,
  i18n_L8RXPcXyde,
  bootstrap_client_Uyd73t1ylZ,
  closable_CQvMsMRv9U,
  components_m1q6StQDkO,
  emitter_eaMgM0oZTb,
  maska_UHaKf2z1iQ,
  setter_OI6M1XQYgl,
  strapi_lgLLCZrkNA,
  toggle_slide_uu97DNS2qu,
  vee_validate_KcKlKmvCrJ
]