export const useProjectHead = () => {
  const runtimeConfig = useRuntimeConfig()
  const projectName = runtimeConfig.public.projectName
  const i18nHead = useLocaleHead({ addSeoAttributes: true }) as any
  const baseURL = runtimeConfig?.public?.baseURL

  useHead({
    title: projectName,
    meta: [
      { name: 'theme-color', content: '#ffffff' },
      { name: 'distribution', content: 'Global' },
      { name: 'Search_pagetype', content: 'content' },
      { name: 'author', content: 'Granobra' },
      { name: 'mobile-web-app-capable', content: 'yes' },
      { property: 'og:type', content: 'website' },
    ],
    link: [
      {
        rel: 'apple-touch-icon',
        sizes: '57x57',
        href: baseURL + '/favicon/apple-icon-57x57.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '60x60',
        href: baseURL + '/favicon/apple-icon-60x60.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '72x72',
        href: baseURL + '/favicon/apple-icon-72x72.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '76x76',
        href: baseURL + '/favicon/apple-icon-76x76.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '114x114',
        href: baseURL + '/favicon/apple-icon-114x114.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '120x120',
        href: baseURL + '/favicon/apple-icon-120x120.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '144x144',
        href: baseURL + '/favicon/apple-icon-144x144.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '152x152',
        href: baseURL + '/favicon/apple-icon-152x152.png',
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: baseURL + '/favicon/apple-icon-180x180.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '192x192',
        href: baseURL + '/favicon/android-icon-192x192.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '32x32',
        href: baseURL + '/favicon/favicon-32x32.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '96x96',
        href: baseURL + '/favicon/favicon-96x96.png',
      },
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: baseURL + '/favicon/favicon-16x16.png',
      },
      {
        rel: 'shortcut icon',
        type: 'image/x-icon',
        href: baseURL + '/favicon/favicon.ico',
      },
      {
        rel: 'icon',
        type: 'image/x-icon',
        href: baseURL + '/favicon/favicon.ico',
      },
      {
        rel: 'manifest',
        href: baseURL + '/favicon/manifest.json',
      },
    ],
  })
}
