<script lang="ts" setup>
  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps({
    errors: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: '',
    },
    selectLabel: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: (props: any) => props.id,
    },
    reduce: {
      type: String,
      default: '',
    },
    options: {
      type: [Object, Array],
      default: () => [],
    },
    class: {
      type: String,
      default: '',
    },
    semidense: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  })
  const modelValue = defineModel()
</script>
<template>
  <label v-if="props.label" class="form-label" :for="props.id">{{ props.label }}</label>
  <Field :id="props.id" v-model="modelValue" :name="props.name" type="text" style="display: none"></Field>
  <VSelect
    v-model="modelValue"
    :label="props.selectLabel"
    :reduce="props.reduce ? (option: any) => option[props.reduce] : (option: any) => option"
    style-class="form-input"
    :class="props.class"
    type="text"
    v-bind="$attrs"
    :options="options"
  >
    <template v-for="slot in Object.keys($slots)" #[slot]="scoped">
      <slot v-bind="scoped" :name="slot"></slot>
    </template>
  </VSelect>
</template>
<style lang="scss" scoped></style>
