<script lang="ts" setup>
  import { computed, reactive } from 'vue'
  import FormGroup from './FormGroup.vue'
  import {
    BirthDateGroup,
    KeyValueGroup,
    CheckboxGroup,
    DatePickerGroup,
    PasswordGroup,
    RadioGroup,
    SelectGroup,
    PriceGroup,
    TextGroup,
  } from '..'

  defineOptions({
    inheritAttrs: false,
  })
  interface IProps {
    type:
      | 'text'
      | 'textarea'
      | 'email'
      | 'file'
      | 'password'
      | 'birthdate'
      | 'datepicker'
      | 'checkbox'
      | 'price'
      | 'radio'
      | 'select'
      | 'keyvalue'
    id?: string
    name?: string | any
    errors?: any
    info?: string
    dataClass?: any
    infoClass?: any
    errorClass?: any
    class?: any
  }
  const props = withDefaults(defineProps<IProps>(), {
    id: '',
    name: (props: any) => props.id,
    errors: '',
    info: '',
    class: '',
    dataClass: '',
    infoClass: '',
    errorClass: '',
  })

  const formClasses = reactive({
    class: '',
    dataClass: '',
    infoClass: '',
    errorClass: '',
  })

  const getComponent = computed(() => {
    if (props.type === 'text' || props.type === 'textarea' || props.type === 'email' || props.type === 'file') {
      return TextGroup
    } else if (props.type === 'password') {
      return PasswordGroup
    } else if (props.type === 'birthdate') {
      return BirthDateGroup
    } else if (props.type === 'datepicker') {
      return DatePickerGroup
    } else if (props.type === 'checkbox') {
      formClasses.class = 'checkbox-group'
      return CheckboxGroup
    } else if (props.type === 'keyvalue') {
      return KeyValueGroup
    } else if (props.type === 'price') {
      return PriceGroup
    } else if (props.type === 'radio') {
      return RadioGroup
    } else if (props.type === 'select') {
      return SelectGroup
    }
    return TextGroup
  })
</script>

<template>
  <FormGroup
    :error="props?.errors?.[props?.name]"
    :info="props.info"
    :class="[props.class, formClasses.class]"
    :data-class="[props.dataClass, formClasses.dataClass]"
    :info-class="[props.infoClass, formClasses.infoClass]"
    :error-class="[props.errorClass, formClasses.errorClass]"
  >
    <component :is="getComponent" :id="props.id" :name="props.name" v-bind="$attrs" :type="props.type" autocomplete="off">
      <template v-for="slot in Object.keys($slots)" #[slot]="scoped">
        <slot v-bind="scoped" :name="slot"></slot>
      </template>
    </component>
  </FormGroup>
</template>

<style lang="scss" scoped></style>
