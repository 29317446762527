<script lang="ts" setup>
  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps({
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: (props: any) => props.id,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
  })
</script>

<template>
  <label v-if="props.label" class="form-label" :for="props.id">{{ props.label }}</label>
  <Field
    :id="props.id"
    v-maska
    data-maska="##.##.####"
    v-bind="$attrs"
    :name="props.name"
    class="form-input"
    type="text"
    :placeholder="props.placeholder"
  />
</template>

<style lang="scss" scoped></style>
