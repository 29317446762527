import Scrollbar from '~/application/Scrollbar/src/Scrollbar.vue'
import Form from '~/application/Form/src/Form.vue'
import FormItem from '~/application/Form/src/FormItem.vue'
import Accordion from '~/application/Accordion/src/Accordion.vue'
import Pagination from '~/application/Pagination/src/Pagination.vue'
import BlazeContainer from '~/application/Carousel/BlazeContainer.vue'
import BlazeSlide from '~/application/Carousel/BlazeSlide.vue'

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.component('Scrollbar', Scrollbar)
  vueApp.component('Form', Form)
  vueApp.component('FormItem', FormItem)
  vueApp.component('Accordion', Accordion)
  vueApp.component('Pagination', Pagination)
  vueApp.component('BlazeContainer', BlazeContainer)
  vueApp.component('BlazeSlide', BlazeSlide)
})
