<script lang="ts" setup>
  import { Form as VFrom } from 'vee-validate'
  import { ref, useSlots } from 'vue'
  const slots = useSlots()
  const props = defineProps({
    tabs: {
      type: Array,
      default: () => [],
    },
    schema: {
      type: Object,
      default: () => { },
    },
  })

  const formRef = ref()
  const activeTab = ref(props.tabs[0])

  const setActiveTab = function (params: any) {
    activeTab.value = params
  }
</script>

<template>
  <VFrom ref="formRef" v-slot="scoped" v-bind="$attrs" :validation-schema="schema">
    <div v-if="tabs.length" class="tab-btn-list">
      <template v-for="btn in tabs" :key="btn">
        <button type="button" class="tab-btn" :class="{ active: activeTab === btn }" @click="setActiveTab(btn)">
          {{ btn }}
        </button>
      </template>
    </div>
    <div class="form-main" :class="{ 'tab-list': tabs }">
      <slot v-bind="scoped" :active-tab="activeTab" name="default"></slot>
    </div>
    <div v-if="slots.caution" class="form-caution">
      <p class="form-caution-text">
        <slot name="caution"></slot>
      </p>
    </div>
  </VFrom>
</template>

<style lang="scss">
</style>
