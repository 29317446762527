<script lang="ts" setup>
  import { watch } from 'vue'
  import { ref, watchEffect } from 'vue'
  import { useSlots } from 'vue'

  const slots = useSlots()
  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps({
    errors: {
      type: [String, Object],
      default: () => '',
    },
    placeholder: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: (props:any) => props.id,
    },
    type: {
      type: String,
      default: 'text',
    },
    labelInside: {
      type: Boolean,
      default: false,
    },
  })
  const key = ref('')
  const value = ref('')
  const modelValue = defineModel({ default: { key: '', value: '' } })

  watchEffect(() => {
    modelValue.value = { key: key.value, value: value.value }
  })
  watch(modelValue, () => {
    if (modelValue.value.key) {
      key.value = modelValue.value.key
    }
    if (modelValue.value.value) {
      value.value = modelValue.value.value
    }
  })
</script>

<template>
  <label v-if="props.label" class="form-label" :class="{ 'form-label-inside': props.labelInside }" :for="props.id">{{ props.label }}</label>
  <div class="form-input-group" :class="{ 'with-suffix': slots.suffix }">
    <span v-if="slots.prefix" class="form-input-prefix">
      <slot name="prefix" />
    </span>
    <Field
      v-bind="$attrs"
      :id="props.id"
      v-model="key"
      :name="props.name"
      class="form-input form-input-double-left"
      :type="props.type"
      :label="props.label"
      :placeholder="props.placeholder[0]"
    />
    <Field
      v-bind="$attrs"
      :id="props.id + 'Value'"
      v-model="value"
      :name="props.name + 'Value'"
      class="form-input form-input-double-right"
      :class="{ 'with-suffix': slots.suffix }"
      :type="props.type"
      :label="props.label"
      :placeholder="props.placeholder[1]"
    />
    <span v-if="slots.suffix" class="form-input-suffix">
      <slot name="suffix" />
    </span>
  </div>
</template>
