interface I18n {
  guide: any
  guideTree: any
}

export const usePatientGuideStore = defineStore({
  id: 'patient-guide-store',
  state: (): I18n => ({
    guide: {},
    guideTree: undefined,
  }),
  getters: {},
  actions: {
    async setPatientGuide(locale: any) {
      if (this.guide?.[locale]) return
      const { find } = useStrapi()
      try {
        const { data } = (await find('patient-guides', {
          populate: {
            localizations: '*',
            banner: {
              populate: '*',
            },
            categories: {
              populate: {
                localizations: '*',
                image: '*',
                dynamic: {
                  populate: {
                    slide: {
                      populate: '*',
                    },
                    card: {
                      populate: '*',
                    },
                    thumbnail: {
                      populate: '*',
                    },
                  },
                },
              },
            },
          },
          locale,
        })) as any
        this.guide[locale] = data

        /*.map((item: any) => {
          item.attributes.link = '/patient-guide/' + item?.attributes?.slug
          item?.attributes?.categories?.data.forEach((subitem: any) => {
            subitem.attributes.link = '/patient-guide/' + item?.attributes?.slug + '/' + subitem?.attributes?.slug
          })
          return item
        })
        */
      } catch {}
    },
    getGuide(): any {
      const { locale } = useI18n()
      return this.guide[locale.value]
    },
    getFirstPage(): any {
      const { locale } = useI18n()
      const index = this.guide?.[locale.value]?.findIndex((item: any) => item?.attributes?.firstPage)
      return this.guide?.[locale.value]?.[index]
    },
    getGuidePage(): any {
      const { locale } = useI18n()
      const route = useRoute()
      const userStore = useUserStore()

      const guideRoot = userStore.getPatientGuideTree.find((item: any) => {
        return item.localizations?.[locale.value]?.link.replace(`/${locale.value}/`, '/') === route.fullPath.replace(`/${locale.value}/`, '/')
      })
      return this.guide?.[locale.value]?.find((item: any) => {
        return (
          item?.attributes?.slug === (guideRoot?.parent ? guideRoot?.parent?.[locale.value]?.slug : guideRoot?.localizations?.[locale.value]?.slug)
        )
      })
    },
    getGuideSubPage(): any {
      const route = useRoute()
      const data = this.getGuidePage()?.attributes?.categories?.data
      if (!data) return
      return data.find((item: any) => item?.attributes?.slug === (route.params?.subslug || route.params?.slug))
    },
  },
})
