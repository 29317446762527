<script lang="ts" setup>
  const i18n = useI18n()
  const { locale } = i18n
  const userStore = useUserStore()

  useLocalizeValidation()
  useProjectHead()

  await Promise.all([userStore.setServices(locale.value), userStore.setPatientGuide(locale.value)])

  i18n.onBeforeLanguageSwitch = async (oldVal, newVal) => {
    await Promise.all([userStore.setServices(newVal), userStore.setPatientGuide(newVal)])
  }
</script>

<template>
  <slot />
</template>

<style lang="scss"></style>
