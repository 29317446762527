import { localize } from '@vee-validate/i18n'
export const useLocalizeValidation = () => {
  const { locale } = useI18n()
  localize(locale.value)
  watch(
    () => locale.value,
    () => {
      localize(locale.value)
    },
  )
}
