export const useGeneralStore = defineStore({
  id: 'general-store',
  state: (): any => ({
    activeContactIdx: 0,
    contact: undefined,
    fixedContact: undefined,
  }),
  getters: {
    getActiveContact(): any {
      return this.contact[this.activeContactIdx]
    },
    getFixedContact(): any {
      const { locale, defaultLocale } = useI18n()
      return computed(() => this.contact?.[locale.value] || this.contact?.[defaultLocale])
    },
  },
  actions: {
    async setContact() {
      //if (this.contact) return
      try {
        const { find } = useStrapi()
        const contactData = (await find('contacts', {
          populate: {
            phones:true,
            bookPhones:true,
            emails:true,
            photo: {
              populate: '*',
            },
          },
          sort: 'order:desc',
        })) as any
        this.contact = contactData.data
      } catch (error) {}
    },
    async setFixedContact() {
      //if (this.fixedContact) return
      try {
        const { find } = useStrapi()
        const contactData = (await find('fixed-contact', {
          populate: '*',
        })) as any

        this.fixedContact = contactData.data.attributes
      } catch (error) {}
    },
  },
})
