<script lang="ts" setup>
  import { computed, ref } from 'vue'

  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps({
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: (props: any) => props.id,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: false,
    },
  })
  const emit = defineEmits(['update:modelValue'])

  const checkedValue = ref()
  const checkboxInputRef = ref()

  const checked = computed(() => {
    if (checkedValue.value !== null && checkedValue.value !== undefined) {
      return checkedValue.value
    }
    return props.modelValue
  })

  const changeEvent = function (event: any) {
    checkedValue.value = event.target.checked
    emit('update:modelValue', event.target.checked)
  }

  const checkboxClick = function () {
    if (props.border) {
      checkboxInputRef.value.click()
    }
  }
</script>

<template>
  <div class="form-checkbox-group" :class="{ border }" @click.self="checkboxClick">
    <Field v-slot="{ field }" :name="props.name">
      <input
        :id="props.id"
        ref="checkboxInputRef"
        :value="checked"
        :checked="checked"
        v-bind="($attrs, field)"
        class="form-checkbox"
        type="checkbox"
        :disabled="disabled"
        @change="changeEvent"
      />
    </Field>
    <label class="form-checkbox-label" :for="props.id">{{ props.label }}</label>
  </div>
</template>

<style lang="scss" scoped></style>
