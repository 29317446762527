<script lang="ts" setup>
  import { computed, ref, watch } from 'vue'

  interface IProps {
    total: number
    max?: number
    prevText?: string
    nextText?: string
    numbers?: boolean
    pageSize?: number
    disabled?: boolean
    siblingCount?: number
    boundaryCount?: number
    showBoundaryButton?: boolean
    showNextPrevButton?: boolean
  }

  const props = withDefaults(defineProps<IProps>(), {
    pageSize: 10,
    disabled: false,
    siblingCount: 2,
    boundaryCount: 0,
    prevText: '<',
    nextText: '>',
    showBoundaryButton: false,
    showNextPrevButton: true,
    numbers: true,
  })
  const emits = defineEmits(['change'])

  const modelValue = defineModel({ default: 1 })
  const totalCount = ref(props.total)
  const totalPage = computed(() => Math.ceil(totalCount.value / props.pageSize))

  watch(
    () => props.total,
    () => {
      totalCount.value = props.total
      modelValue.value = 1
    },
  )

  const showedPage = function (e: number) {
    const totalShow = 5
    const showGap = 2
    const showDif = totalShow - showGap
    //modelValue.value = e
    if (totalPage.value > totalShow) {
      if (e < showDif) {
        return [1, 2, 3, 4, 5]
      } else if (e >= totalPage.value - showGap) {
        const arr: number[] = []
        for (let i = 0; i < totalShow; i++) {
          arr.unshift(totalPage.value - i)
        }
        return arr
      } else {
        return [e - 2, e - 1, e, e + 1, e + 2]
      }
    } else {
      const countingNumber: number[] = []
      for (let i = 1; i <= totalPage.value; i++) {
        countingNumber.push(i)
      }
      return countingNumber
    }
  }
  const movePage = function (val: string | number) {
    if (props.disabled) return
    if (typeof val === 'number') {
      modelValue.value = val
    } else if (val === 'next') {
      if (modelValue.value < totalPage.value) {
        modelValue.value++
      }
    } else if (val === 'prev') {
      if (modelValue.value !== 1) {
        modelValue.value--
      }
    } else if (val === 'last') {
      modelValue.value = totalPage.value
    } else if (val === 'first') {
      modelValue.value = 1
    }
    emits('change', modelValue.value)
  }
</script>
<template>
  <div class="pagination-root" v-if="total">
    <slot v-if="showBoundaryButton" name="first" :action="() => movePage('first')">
      <button class="pagination-button pagination-button-first" :disabled="modelValue == 1" @click="movePage('first')">&lt;&lt;</button>
    </slot>
    <slot v-if="showNextPrevButton" name="prev" :action="() => movePage('prev')" :disabled="modelValue == 1">
      <button class="pagination-button pagination-button-prev" :disabled="modelValue == 1" @click="movePage('prev')">{{ prevText }}</button>
    </slot>
    <template v-if="numbers">
      <template v-for="item in showedPage(modelValue)" :key="item">
        <button class="pagination-button" :class="{ active: item === modelValue }" :value="item" @click="() => movePage(item)">
          {{ item }}
        </button>
      </template>
    </template>
    <slot
      v-if="showNextPrevButton"
      name="next"
      :action="() => movePage('next')"
      :disabled="modelValue == showedPage(modelValue).pop() || showedPage(modelValue).length == 1"
    >
      <button
        class="pagination-button pagination-button-next"
        :disabled="modelValue == showedPage(modelValue).pop() || showedPage(modelValue).length == 1"
        @click="movePage('next')"
      >
        {{ nextText }}
      </button>
    </slot>
    <slot v-if="showBoundaryButton" name="last" :action="() => movePage('last')">
      <button
        class="pagination-button pagination-button-last"
        :disabled="modelValue == showedPage(modelValue).pop() || showedPage(modelValue).length == 1"
        @click="movePage('last')"
      >
        >>
      </button>
    </slot>
  </div>
</template>

<style lang="scss">
  .pagination {
    &-root {
      display: flex;
      gap: 0.25rem;
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      //width: 26px;
      height: 26px;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      padding: 0.5rem;
      border-radius: 3px;
      transition: none;
      &:hover {
        background-color: rgba(34, 51, 51, 0.164);
      }
      &.active {
        color: #fff;
        background-color: #102466;
      }
    }
  }
</style>
