<script lang="ts" setup>
  const props = defineProps({
    error: {
      type: [Object, String],
      default: '',
    },
    info: {
      type: String,
      default: '',
    },
    class: {
      type: [String, Array, Object],
      default: '',
    },
    dataClass: {
      type: [String, Array, Object],
      default: 'form-group-data',
    },
    infoClass: {
      type: [String, Array, Object],
      default: '',
    },
    errorClass: {
      type: [String, Array, Object],
      default: '',
    },
  })
</script>

<template>
  <div class="form-group" :class="[props.class, { 'has-error': error }]">
    <div class="form-group-data">
      <slot />
    </div>
    <span v-if="props.info" class="form-checkbox-info" :class="infoClass">{{ props.info }}</span>
    <span v-if="props.error" class="form-error" :class="errorClass">{{ props.error }}</span>
  </div>
</template>

<style lang="scss" scoped></style>
