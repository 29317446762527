<script lang="ts" setup>
  import DatePicker from '~/application/DatePicker'
  defineOptions({
    inheritAttrs: false,
  })
  const props = defineProps({
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      default: (props: any) => props.id,
    },
  })
</script>

<template>
  <label v-if="props.label" class="form-label" :for="props.id">{{ props.label }}</label>

  <DatePicker :id="props.id" :name="props.name" v-bind="$attrs">
    <slot />
  </DatePicker>
</template>

<style lang="scss" scoped></style>
