<script lang="ts" setup>
  import BlazeSlider from 'blaze-slider'
  type IProps = {
    navigation?: boolean
    pagination?: boolean
    options?: any
  }

  const props = withDefaults(defineProps<IProps>(), {
    navigation: false,
    pagination: false,
  })
  const blazeRef = ref()
  
  onMounted(() => {
    new BlazeSlider(blazeRef.value, props.options)
  })
</script>

<template>
  <div ref="blazeRef" class="blaze-slider">
    <div class="blaze-container">
      <div class="blaze-track-container">
        <div class="blaze-track">
          <slot />
        </div>
      </div>
    </div>
    <div class="blaze-indicators">
      <button v-if="navigation" class="blaze-prev" aria-label="Go to previous slide"></button>
      <div v-if="pagination" class="blaze-pagination"></div>
      <button v-if="navigation" class="blaze-next" aria-label="Go to next slide"></button>
    </div>
  </div>
</template>

<style lang="scss"></style>
